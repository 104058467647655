import axios from 'axios';

export const getAuthToken = async () => {
    try {
        const response = await axios.post('https://api.tuinschuren.nl/v1/auth', {}, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
        if (response.status !== 200) {
            throw new Error('Failed to authenticate');
        }
    } catch (error) {
        console.error('Error authenticating:', error);
        throw error;
    }
};
