module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackPageViews":true,"events":true,"eventsGlobal":"sa_event","ignorePages":["pathname"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-16855465524"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"origin":"https://api.tuinschuren.nl/v1","delayOnRouteUpdate":0,"exclude":[]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
