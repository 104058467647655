import { v4 as uuidv4 } from 'uuid';

declare global {
	interface Window {
		Cookiebot: {
			consent: {
				marketing: boolean;
			};
		};
		gtag: any;
		fbq: any;
		ttq: any;
		sa_loaded: any;
		sa_event: (eventName: string, properties?: Record<string, any>) => void;
	}
}

const metaEvent = ({ eventType, eventId }: any) => {
	const fbPixelId = process.env.GATSBY_FACEBOOK_PIXEL_ID;
    const fbDefaultEvents = ['PageView', 'Lead', 'Contact']

	if (typeof window === "undefined" || !window.Cookiebot?.consent.marketing) return;

	if (!document.getElementById('facebook-pixel-script')) {
		const pixelScript = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq.disablePushState = true
            fbq.allowDuplicatePageViews = true
            fbq('init', '${fbPixelId}');
        `;
		const scriptTag = document.createElement("script");
		scriptTag.id = 'facebook-pixel-script';
		scriptTag.innerHTML = pixelScript;
		document.head.appendChild(scriptTag);
	}

    window.fbq(`${fbDefaultEvents.includes(eventType) ? "track" : "trackCustom"}`, `${eventType}`, {}, { eventID: `${eventId}` });
};

const googleAdsEvent = ({ eventType }: any) => {
    const gTagId = 'AW-16855465524'

    if (typeof window === "undefined" || !window.Cookiebot?.consent.marketing || !window.gtag) return;

    window.gtag("event", "conversion", {
        send_to: `${gTagId}/${eventType}`,
    });
};

const simpleAnalyticsEvent = ({ eventType, elementId }: any) => {
    if (typeof window === "undefined" || !window.sa_loaded) return;
    window.sa_event(eventType, { element_id: elementId });
};

export default function trackEvent({
    eventType,
    elementId
}:Props) {
    const eventTypeMap = {
        facebook: {
            page_view: 'PageView',
            lead: 'Lead',
            contact: 'Contact',
        } as Record<string, string>,
        google_ads: {
            lead: '2nEUCMSOkpoaELT8p-U-',
            contact: 'Z6EyCL7QoZoaELT8p-U-',
        } as Record<string, string>,
        simple_analytics: {
            button_clicked: 'button_clicked',
            lead: 'lead',
            contact: 'contact',
            scrolled_25: 'scrolled_25',
            scrolled_50: 'scrolled_50',
            scrolled_75: 'scrolled_75',
            scrolled_100: 'scrolled_100',
            modal_opened: 'modal_opened',
            modal_closed: 'modal_closed',
        } as Record<string, string>,
    }

    const eventId = uuidv4()

    if (eventType in eventTypeMap.simple_analytics) {
        simpleAnalyticsEvent({
            eventType: eventTypeMap['simple_analytics'][eventType],
            elementId: elementId
        });
    }

    if (eventType in eventTypeMap.google_ads) {
        googleAdsEvent({
            eventType: eventTypeMap['google_ads'][eventType]
        });
    }
    

    if (eventType in eventTypeMap.facebook) {
        metaEvent({
            eventType: eventTypeMap['facebook'][eventType],
            eventId: eventId
        });
    }

}




interface Props {
    eventType: 'button_clicked' | 'lead' | 'contact' | 'scrolled_25' | 'scrolled_50' | 'scrolled_75' | 'scrolled_100' | 'page_view' | 'modal_opened' | 'modal_closed'
    elementId?: string;
}