import "./src/styles/global.sass"
import '@fontsource-variable/nunito'
import '@fontsource/roboto'
import { getAuthToken } from "./src/utils/getAuthToken"
import trackEvent from "./src/utils/trackEvent"

let isInitialLoad = true;

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined') {
    if (!window.location.hostname.includes('localhost')) {
      getAuthToken()
    }
    window.addEventListener("CookiebotOnAccept", () => {
      trackEvent({
        eventType: "page_view",
      });
    });
  }
};

export const onRouteUpdate = () => {
  if (isInitialLoad && typeof window !== 'undefined') {
		isInitialLoad = false;
	} else {
		trackEvent({
			eventType: "page_view",
		});
	}
};